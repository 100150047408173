import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xl2: 1300,
  xxl: 1440,
  xxl2: 1499,
}

export const colors: Colors = {
  primary100: '#571E3D',
  primary50: '#782954',
  alert50: '#ff5858',
  white: '#FFFFFF',
  black: '#000000',
  gray5: '#ececec',
  gray0: '#F4F4F4',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
