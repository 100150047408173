import { css, createGlobalStyle } from 'styled-components'

import Reset from 'styles/reset'
import Normalize from 'styles/normalize'

const Global = css`
  @font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/ProximaNova-Bold.woff2') format('woff2'),
      url('/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),
      url('/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  html,
  body {
    position: relative;
    font-family: 'Proxima Nova', sans-serif;
  }

  html {
    font-size: 20px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.black};
  }

  body {
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  img {
    margin: 0;
    padding: 0;
  }

  button {
    font-family: 'Proxima Nova', sans-serif;
  }
`

const GlobalStyles = createGlobalStyle`
  ${Normalize}
    ${Reset}
    ${Global} 
`

export default GlobalStyles
